import React from 'react';
import Header from "../../components/Header/Header.comp";

const Main = (props) => {
    const { children } = props;

    return (
        <>
            <Header />
            <>
                {children}
            </>
        </>
    );
}

export default Main;