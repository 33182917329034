import React from 'react';

const Minimal = (props) => {
    const { children } = props;

    return (
        <>{children}</>
    );
}

export default Minimal;