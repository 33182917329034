import React, {useState, useEffect} from 'react';

import {
    useGlobalState
} from "../../../context/global_state";

export const TOAST_TYPES = {
    SUCCESS: 1,
    WARNING: 2,
    ERROR: 3
}

const Toast = () => {

    // const [show, setShow] = useState(false);
    const [toastClasses, setToastClasses] = useState(`${'toast'} ${'toast_hide'}`)

    const { toastShow, toastMessage, setToastShow, setToastMessage } = useGlobalState();

    useEffect( () => {
        if (toastShow) {
            setToastClasses(`${'toast'} ${'toast_show'}`)
        } else {
            setToastClasses(`${'toast'} ${'toast_hide'}`)
        }
        setTimeout(()=> {
            if (toastShow) {
                setToastShow(false);
                setTimeout( ()=> {
                    setToastMessage('');
                }, 500)
            }
        }, 5000)
    }, [toastShow])


    return(
        <div className={'container'}>
            <div className={toastClasses}>
                <p className={'toast_text'}>{toastMessage}</p>
            </div>
        </div>

    )
}

export default Toast;


