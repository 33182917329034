import React, {useEffect, useState} from "react";
import Draggable from "react-draggable";
import {getActiveThemeColor} from "../../../utils/utils";
import {useGlobalState} from "../../../context/global_state";

import raz_slider_mark from "../../../assets/raz/raz-slider-mark.png";

const TFQSliderPoster = (props) => {

    const {choices, onChoiceClick} = props;
    //const [slidingBarWidth, setSlidingBarWidth] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(2);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const { profile } = useGlobalState();
    const slidingBarRef = React.createRef();

    useEffect( () => {
        setSelectedIndex(selectedIndex);
        onChoiceClick(selectedIndex);
        setPosition({x: 40/100*slidingBarRef.current.offsetWidth, y: 0});
        //setSlidingBarWidth(slidingBarRef.current.offsetWidth);

    }, [choices] );

    const onSliderDrag = (event, info) => {
        //TODO: Clean the code
        //TODO: Make sure choices order is T,F,NS otherwise selectedIndex will be wrong
        //TODO: User setAnswer instead of slectedIndex to avoid confusion in case of TFQ
        let sliderPosition = info.x / slidingBarRef.current.offsetWidth * 100;
        if(sliderPosition < 20 && selectedIndex != 1){
            onChoiceClick(1);
            setSelectedIndex(1);
        }else if(sliderPosition >= 20 && sliderPosition < 58 && selectedIndex != 2){
            onChoiceClick(2);
            setSelectedIndex(2);
        }else if(sliderPosition >= 58 && selectedIndex != 0){
            onChoiceClick(0);
            setSelectedIndex(0);
        }
    }

    const onSliderStop = (event, info) => {
        setPosition({x: info.x, y: 0});
    }

    const sliderAnswerColor = (index) => {
        return selectedIndex === (index+1)%3 ? {color: getActiveThemeColor(profile)} : {};
    }

    const sliderAnswerClick = (index) => {
        onChoiceClick((index+1) % choices.length);
        setSelectedIndex((index+1) % choices.length);
        setPosition({x: (index*34+5)/100*slidingBarRef.current.offsetWidth, y: 0});
    }

    return (
        <div className={'qt-slider-container'}>
            <div className={'qt-slider-section'} ref={slidingBarRef} >
                <Draggable axis="x" position={position} bounds="parent" onDrag={onSliderDrag} onStop={onSliderStop}>
                    <img className={'qt-slider-img'} src={raz_slider_mark} />
                </Draggable>
            </div>
            <div className={'qt-slider-answers'}>
            {
                choices.map( (choice, index) => <span
                    style={sliderAnswerColor(index)}
                    className={'qt-slider-answer-span'}
                    onClick={() => {sliderAnswerClick(index)}}
                >{choice}</span> )
            }
            </div>
        </div>
    )
};

export default TFQSliderPoster;