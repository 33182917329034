import React, {useEffect} from 'react';
import {Route, useHistory, useLocation} from 'react-router-dom';
import {DEFAULT_LOCALE, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../utils/constants";
import {sha256} from "js-sha256";
import {urlRewriteUtil} from "./common.wrapper";

const RouteWithLayout = (props) => {

    const history = useHistory();

    const location = useLocation();

    const { layout: Layout, component: Component, ...rest } = props;

    useEffect( () => {
        if (process.env.REACT_APP_MAINTENANCE_LOCK === '1') {
            const access_key = localStorage.getItem(LOCAL_STORAGE_KEYS.UNDER_MAINTENANCE_ACCESS_KEY);
            const pass_key = process.env.REACT_APP_UNDER_MAINTENANCE_PASS_KEY
            const pass_hash = sha256(pass_key);

            if (access_key !== pass_hash) {
                history.push('/lock');
            }
        }

        urlRewriteUtil(location, history);
    }, [] )

    return (
        <Route
            {...rest}
            render={matchProps => (
                <Layout>
                    <Component {...matchProps} />
                </Layout>
            )}
        />
    );
}

export default RouteWithLayout;