import React, {useEffect, useState} from 'react';
import Spinner from "../../assets/Spinner-1s-272px.svg";

import { withRouter } from "react-router";

const Splash = (props) => {

    const { history } = props;

    // eslint-disable-next-line no-unused-vars
    const [loaded, setLoaded] = useState(false);

    useEffect( () => {

        // setTimeout( () => {
        //     setLoaded(true);
        // }, 1000)

        // un-comment above block and add logic, if needed any pre-load data before going home
        history.push('/home', true);
    }, [])



    return (
        <div className={'splash-container'}>
            {
                loaded ? <div className={'splash-heading'}>Loaded</div> : <img
                    height={400}
                    src={Spinner} />
            }
        </div>
    );
}

export default withRouter(Splash);
