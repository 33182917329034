import React from "react";
import Spinner from "../../assets/Spinner-1s-272px.svg";

export const LoaderDiv = (props) => {

    const { showLabel } = props;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "auto",
                height: '100vh',
            }}
        >

            <img
                height={300}
                src={Spinner}
                style={{
                    marginTop: '-15vh'
                }}
            />

            {
                showLabel && <h2
                    style={{
                        textAlign: 'center',
                        marginTop: '-3vh'
                    }}>{"Loading"}</h2>
            }

        </div>
    )
}

const LoaderWithBackDrop = (props) => {

    const { loading } = props;

    return (
        <>
            {
                loading && <div className={'backdrop-loading-container'}>
                    <LoaderDiv
                        showLabel={false}
                    />
                </div>
            }
        </>
    );
};

export default LoaderWithBackDrop;