import {getGuestUser} from "../../services/app_start.service";
import {LOCAL_STORAGE_KEYS} from "../../utils/constants";


export const loadNewGuestUser = async (setAuthToken, setAuthUser,
                                       setToastShow, setToastMessage,
                                       setProfile, history, user_group) => {

    const org_code = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE);
    let profile;

    try {
        const result = await getGuestUser(org_code, user_group);
        console.log('result.data -> ', result.data);
        if (result.data && result.data.user) {
            await setAuthToken(result.data.auth_token);
            await setAuthUser(result.data.user);

            profile = result.data.profile
            await setProfile(profile);

        }
    } catch (e) {
        setToastShow(true);
        setToastMessage(e.message);
    }

    // add the local_storage variable with name FIRST_TIME_BOOT
    localStorage.setItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT, true);

    // if (goto_dq_info_page) {
    //     history.push('/info_dq')
    // } else {
    //     history.push('/home')
    // }

    // commented area was coded with prev-logic, to have guest login link at register/loing page

    // following routing is true for all cases, if above commented code do become active anytime in future,
    // adjust following in it



    if (!profile) {
        return history.push('/home')
    }

    if (profile.is_demographic_updated) {
        history.push('/home')
    } else {
        history.push('/demographic')
    }
}