
export const StepType = {
    GUIDE: 1,
    QUESTION: 2,
    PAIRED_QUESTION: 3
}

export const QuestionType = {
    MCQ: 1
}
