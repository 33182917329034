import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {useLocation} from "react-router-dom";

import {GET_TERMS_OF_USE_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const TermsOfUse = (props) => {

    const {history} = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const goBack = () => {
        // history.goBack();
    }

    return (
        <div className="static-page-container">
            <img className='dq-logo' onClick={goBack} src={dqIcon} />

            <div className="static-page-title-text">
                {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__title')}
            </div>

            <div className="static-page-section" id="section1">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__intro')}
                </p>
                <p className="static-page-section-content">
                    {ReactHtmlParser(GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__opening_paragraph_1'))}
                </p>

                <p className="static-page-section-content   ">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__opening_paragraph_2')}
                </p>
            </div>

            <div className="static-page-section" id="section2">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_2')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_2_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_2_2')}
                </p>
            </div>

            <div className="static-page-section" id="section3">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_3')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_3_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_3_2')}
                </p>
            </div>

            <div className="static-page-section" id="section4">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_4')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__paragraph_3')}
                </p>
            </div>

            <div className="static-page-section" id="section5">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_5')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_5_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_5_2')}
                </p>
            </div>

            <div className="static-page-section" id="section6">
                <p className="static-page-section-title">
                    {ReactHtmlParser(GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_6'))}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_6_1')}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_6_2')}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_6_3')}
                </p>
            </div>

            <div className="static-page-section" id="section7">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_7')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__paragraph_4')}
                </p>
            </div>
            <div className="static-page-section" id="section8">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_8')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_8_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_8_2')}
                </p>
            </div>

            <div className="static-page-section" id="section9">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_9')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_9_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_9_2')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_9_3')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_9_4')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_9_5')}
                </p>
            </div>

            <div className="static-page-section" id="section10">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_10')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__paragraph_5')}
                </p>
            </div>

            <div className="static-page-section" id="section11">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_11')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__paragraph_6')}
                </p>
            </div>

            <div className="static-page-section" id="section12">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_12')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__para_7')}
                </p>
            </div>

            <div className="static-page-section" id="section13">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_13')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__para_8')}
                </p>
            </div>

            <div className="static-page-section" id="section14">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_14')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__para_9')}
                </p>
            </div>

            <div className="static-page-section" id="section15">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_15')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_15_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_15_2')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_15_3')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_15_4')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_15_5')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_15_6')}
                </p>
            </div>

            <div className="static-page-section" id="section16">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_16')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_16_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_16_2')}
                </p>
            </div>

            <div className="static-page-section" id="section17">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_17')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_17_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_17_2')}
                </p>
            </div>

            <div className="static-page-section" id="section18">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_2')}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_3')}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_4')}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_5')}
                </p><p className="static-page-section-content">
                {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_5_1')}
            </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_5_2')}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_5_3')}
                </p>
                <p></p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_6')}
                </p>

                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_7')}
                </p><p className="static-page-section-content">
                {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_7_1')}
            </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_7_2')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_7_3')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_18_7_4')}
                </p>
                <p></p>
            </div>

            <div className="static-page-section" id="section19">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_2')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_3')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_4')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_5')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_6')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_7')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_8')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_9')}
                </p>
                <p className="static-page-section-content">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_19_1_10')}
                </p>
            </div>

            <div className="static-page-section" id="section20">
                <p className="static-page-section-title">
                    {GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__point_20')}
                </p>
                <p className="static-page-section-content  mb-3">
                    {ReactHtmlParser(GET_TERMS_OF_USE_LOCALIZE_TEXT(pageLc, 'terms_of_use__end_statement'))}
                </p>
            </div>
        </div>
    );
}

export default withRouter(TermsOfUse);
