import React from 'react';

const BackDrop = (props) => {
    const { closeDrawer } = props;

    return(
        <div className={'backdrop'} onClick={closeDrawer}/>
    )
}

export default BackDrop;