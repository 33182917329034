import React, {useEffect} from 'react';
import {Route, Redirect, useHistory, useLocation} from 'react-router-dom';
import { useAuth } from "../../context/auth";
import {DEFAULT_LOCALE, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../utils/constants";
import {sha256} from "js-sha256";
import {urlRewriteUtil} from "./common.wrapper";

const PrivateRouteWithLayout = (props) => {

    const history = useHistory();

    const location = useLocation();

    const { layout: Layout, component: Component, ...rest } = props;
    const { authToken, authUser } = useAuth();

    useEffect( () => {
        if (process.env.REACT_APP_MAINTENANCE_LOCK === '1') {
            const access_key = localStorage.getItem(LOCAL_STORAGE_KEYS.UNDER_MAINTENANCE_ACCESS_KEY);
            const pass_key = process.env.REACT_APP_UNDER_MAINTENANCE_PASS_KEY
            const pass_hash = sha256(pass_key);

            if (access_key !== pass_hash) {
                history.push('/lock');
            }
        }

        // check if set password is requried
        const require_to_setpass = localStorage.getItem(LOCAL_STORAGE_KEYS.FORCE_TO_SET_PASSWORD);
        if (require_to_setpass && require_to_setpass === 'true') {
            history.push('/complete_registration');
        }

        urlRewriteUtil(location, history);
    }, [] )

    return (
        <Route
            {...rest}
            render={matchProps => (
                authToken && authUser ? (
                    <Layout>
                        <Component {...matchProps} />
                    </Layout>
                ) : (
                    <Redirect
                        to={{ pathname: "/login", referer: matchProps.location}}
                    />
                )
            )}
        />
    );
}

export default PrivateRouteWithLayout;