import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {useLocation} from "react-router-dom";
import {GET_COOKIES_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const CookiePolicy = (props) => {

    const {history} = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const goBack = () => {
        // history.goBack();
    }

    return (
        <div className="static-page-container">
            <img className='dq-logo' onClick={goBack} src={dqIcon} />

            <div className="static-page-title-text">
                {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__title')}
            </div>

            <div className="static-page-section" id="section1">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__effective_date')}
                </p>
                <p className="static-page-section-content">
                    {ReactHtmlParser(GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__policy_desc'))}
                </p>
            </div>

            <div className="static-page-section" id="section2">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__what_is_it')}
                </p>
                <p className="static-page-section-content">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_1')}
                </p>
                <p className="static-page-section-content">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_2')}
                </p>
                <p className="static-page-section-content">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__different_example')}
                </p>
                <ul>
                    <li>
                        {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__desc_example_1')}
                    </li>
                    <li>
                        {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__desc_example_2')}
                    </li>
                </ul>
            </div>

            <div className="static-page-section" id="section3">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__which_to_use')}
                </p>
                <p className="static-page-section-content">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__which_to_use_explain')}
                </p>
                <table className='cookie-table'>
                    <thead>
                        <tr>
                            <th>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__type_of_cookie')}</th>
                            <th>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__source')}</th>
                            <th>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__what_these_do')}</th>
                            <th>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__how_to_exercise_choice')}</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__essential_web_cookies')}</td>
                        <td>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__dq_index_dc_assessment')}</td>
                        <td>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_3')}</td>
                        <td>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_4')}</td>
                    </tr>
                    <tr>
                        <td>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__analytics_cookies')}</td>
                        <td><a href='https://support.google.com/analytics/answer/6004245' target={'_blank'}>
                            {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__google_analytics')}
                        </a></td>
                        <td>
                            <div className='cookie-lbl'>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_5_title')}</div>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_5')}</p>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_5_part')}</p>
                            <div className='cookie-lbl'>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__legal_basis')}</div>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__data_processed_on_consent_art_6_1a')}</p>
                            <div className='cookie-lbl'>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__recipients')}</div>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_6')}</p>

                            <div className='cookie-lbl'>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__retention_period')}</div>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_7')}</p>
                            <div className='cookie-lbl'>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__third_country_transfer')}</div>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_8')}</p>

                            <div className='cookie-lbl'>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__required_provision')}</div>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_9')}</p>
                            <div className='cookie-lbl'>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__profiling')}</div>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_10')}</p>
                            <p>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_11')}</p>

                            <ul>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__understand_how_many_active_users')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_12')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_13')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_14')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__help_use_uncover')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_15')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__understand_how_many_active_users_realtime')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__find_out_devices_text')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__track_how_users_navigate')}</li>
                                <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__track_users_retention')}</li>
                            </ul>
                        </td>
                        <td>
                            {ReactHtmlParser(GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_16'))}
                            <p>{ReactHtmlParser(GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_16_2'))}</p>
                        </td>
                    </tr>
                </table>
            </div>

            <div className="static-page-section" id="section4">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__info_collect_via_cookie')}
                </p>
                <p className="static-page-section-content">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_17')}
                </p>
                <ul>
                    <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__unique_id_tokens')}</li>
                    <li>{GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__info_about_current_session')}</li>
                </ul>
            </div>

            <div className="static-page-section" id="section5">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__how_restrict_cookies')}
                </p>
                <p className="static-page-section-content">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_18')}
                </p>
            </div>

            <div className="static-page-section" id="section6">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__web_opt_out')}
                </p>
                <p className="static-page-section-content">
                    {ReactHtmlParser(GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_19'))}
                </p>
            </div>

            <div className="static-page-section" id="section7">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__mobile_opt_out')}
                </p>
                <p className="static-page-section-content">
                    {ReactHtmlParser(GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__para_20'))}
                </p>
            </div>
            <div className="static-page-section" id="section8">
                <p className="static-page-section-title">
                    {GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__contact_us')}
                </p>
                <p className="static-page-section-content">
                    {ReactHtmlParser(GET_COOKIES_LOCALIZE_TEXT(pageLc, 'cookies__closing_statement'))}
                </p>
            </div>
        </div>
    );
}

export default withRouter(CookiePolicy);
