import React, {useState, useEffect, useRef} from "react";

import { Animate } from "react-simple-animate";
import {getActiveThemeColor} from "../../../utils/utils";
import {useGlobalState} from "../../../context/global_state";
import right_arrow from "../../../assets/images/right-arrow.png";

const _randomNumberStr = (min, max) => {
    return ((Math.random() * (max - min + 1)) + min).toFixed(0);
}

const _randomNumber = (min, max) => {
    return ((Math.random() * (max - min + 1)) + min);
}

const BalloonGamePoster = (props) => {

    const {choices, onChoiceClick, questionNextAction} = props;

    const { profile } = useGlobalState();

    const [game_ended, setGameEnded] = useState(false);
    const [game_started, setGameStarted] = useState(false);
    const [balloon_visible, setBalloon_visible] = useState([])

    const selectedBalloons = useRef([]);

    useEffect( () => {
        setGameEnded(false);
        setGameStarted(false);
        if (choices.length > 1) {
            selectedBalloons.current = [];
            const _bal_vis = new Array(choices.length).fill(false);
            // _bal_vis[0] = true;
            setBalloon_visible([..._bal_vis]);
            setGameEnded(false);
        }
    }, [choices] )

    useEffect( ()=> {
        if (game_ended) {
            // auto move to next question
            console.log('CRITICAL ----------- MOVING TO NEXT QUESTION')
            questionNextAction()
            setGameEnded(false);
        }
    }, [game_ended])

    const startTheGame = () => {
        console.log('start game is clicked !!! ');
        setGameStarted(true);
        const _bal_vis = balloon_visible;
        _bal_vis[0] = true;
        setBalloon_visible([..._bal_vis]);
    };

    const nextBalloon = (index) => {
        if (!game_ended) {
            const _bal_vis = balloon_visible;
            let nx_balloon = -1;
            _bal_vis[index] = false;

            if (selectedBalloons.current.length+1 === choices.length) {
                console.log('FINISHED GAME')
                setGameEnded(true)
            } else {
                if (index+1 < choices.length) {
                    nx_balloon = index+1;
                }
                else {
                    nx_balloon = 0;
                }
                _bal_vis[nx_balloon] = true;
                setBalloon_visible([..._bal_vis]);
                validateNextBalloon(nx_balloon);
            }
        } else {
            // hide all balloons
            let _bal_vis = balloon_visible;
            _bal_vis = _bal_vis.map(b=>false);
            setBalloon_visible([..._bal_vis]);
        }
    }

    const validateNextBalloon = (nx_index) => {
        if (selectedBalloons.current.length+1 === choices.length) {
            console.log('FINISHED GAME')
            setGameEnded(true)
        } else if (selectedBalloons.current.includes(nx_index)) {
            nextBalloon(nx_index)
        }
    }

    const balloonClick = (index) => {
        onChoiceClick(index);
        selectedBalloons.current.push(index);
        nextBalloon(index);
    }

    const balloonFloated = (index) => {
        nextBalloon(index)
    }


    return (
        <>
            <div className="balloons">
                <div className="blns">

                    {
                        choices.length > 1 && choices.map( (choice, index) => <Animate
                            play={balloon_visible[index]}
                            onComplete={() => balloonFloated(index)}
                            duration={ _randomNumber(7,10)}
                            start={{
                                transform: `translate(${_randomNumberStr(0, 25)}vw, 0px)`
                            }}
                            end={{
                                transform: `translate(${_randomNumberStr(5, 70)}vw, -120vh)`
                            }}
                        >
                            <div
                                style={{
                                    visibility: balloon_visible[index] ? "visible" : "hidden"
                                }}
                                className={`bln-${index%5} bln-large`}>
                                <span
                                    style={{
                                        visibility: balloon_visible[index] ? "visible" : "hidden"
                                    }}
                                    onClick={() => balloonClick(index)}
                                >{`${index+1}. ${choice}`}</span>
                            </div>
                        </Animate> )
                    }

                </div>
            </div>
            {
                choices.length > 1 && <div
                    style={{
                        visibility: !game_started ? "visible" : "hidden"
                    }}
                >
                    <div
                        className={'balloon_game_start_btn_container'}
                    >
                        <div
                            onClick={startTheGame}
                            style={{
                                background: getActiveThemeColor(profile)
                            }}
                            className={'balloon_game_start_btn'}
                        >
                            <img className={'balloon_game_start_btn_img'} src={right_arrow} />
                        </div>
                    </div>
                </div>
            }
        </>

);

}

export default BalloonGamePoster