import React, {useEffect} from 'react';
import { withRouter } from "react-router";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";


const CollabPage = (props) => {

    const {history, location} = props

    useEffect( ()=> {
        const params = new URLSearchParams(location.search);
        const query_str = params.get('q');
        if (query_str) {
            console.log('query_str --> ', query_str);
            localStorage.setItem(LOCAL_STORAGE_KEYS.COLLAB_STRING, query_str);
        }
        history.push('/info_dq')
    }, [] )

    return (
        <p
            style={{
                padding: '15vw',
                textAlign: "center"
            }}
        >Please Wait...</p>
    );
};


export default withRouter(CollabPage);
