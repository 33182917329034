import React from 'react';

const Settings = () => (
	<div className={'setting-container'}>
		<h1>Settings</h1>
		<p>This is the Settings component.</p>
	</div>
);

export default Settings;
