import axios from 'axios';
import {DEFAULT_ORG_CODE, LOCAL_STORAGE_KEYS} from "./constants";

axios.defaults.headers.post['Content-Type'] = 'application/json';
const base_uri = process.env.REACT_APP_MYDQ_API;

const logError = (message) => {
    console.log('error_message from API --> ', message);

}

export async function invokeApi(
    {
        path,
        method = 'GET',
        headers = {},
        queryParams = {},
        postData = {},
    }
) {

    const org_code = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE)
    if (org_code) {
        headers = {
            ...headers,
            'xc-org-code': org_code
        }
    } else {
        const active_user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_USER));
        if (active_user && active_user.is_guest_user) {
            headers = {
                ...headers,
                'xc-org-code': DEFAULT_ORG_CODE
            }
        }
    }

    let reqObj = {
        method,
        url     : base_uri + path,
        headers
    };

    reqObj['params'] = queryParams;

    if (method === 'POST') {
        reqObj['data'] = postData;
    }

    let results;

    try {
        // fall back option if org still not in request
        if (!headers['xc-org-code']) {
            const org_code_store = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE);
            if (org_code_store) {
                headers['xc-org-code'] = org_code_store;
            }
        }

        results = await axios(reqObj);
        return results.data;
    } catch (error) {

        if (error.response.status === 499) throw new Error('Permission denied');

        if(error.response.status === 401) {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_USER);
            window.location.reload();
        }

        if (!(error.response && error.response.data && error.response.data.message)) {
            logError('NETWORK ERROR : Some Thing Went Wrong!');
            throw new Error('NETWORK ERROR : Some Thing Went Wrong!');

        } else {
            logError(error.response.data.message);
            throw new Error(error.response.data.message);
        }
    }
}
