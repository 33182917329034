import React, {useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import {saveUserGroup} from "../../../services/app_start.service";
import {useGlobalState} from "../../../context/global_state";
import {loadNewGuestUser} from "../auth_common.lib";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {useAuth} from "../../../context/auth";

import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {DEFAULT_LOCALE, LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import Footer from "../../../components/Footer/plugable.footer";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

const options = [
`Students 
(Ages 13-18)`, 
`Non-Working 
Adults 
(Ages 19+)`, 
`Working 
Professional`
];

const DqOnlyInfo = (props) => {

    const { history } = props;
    const location = useLocation();

    const [selectedOpt, setSelectedOpt] = useState(-1);
    const [collabOption, setCollabOption] = useState('')
    const [loading, setLoading] = useState(false);

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();
    const { setAuthToken, setAuthUser } = useAuth();

    const pageLc = location.pathname.split('/')[1];

    useEffect( () => {
        const collab_value_raw = localStorage.getItem(LOCAL_STORAGE_KEYS.COLLAB_STRING);
        if (collab_value_raw) {
            const collab_value = atob(collab_value_raw);
            const collab_obj = JSON.parse(collab_value);
            if (collab_obj.org_ref && collab_obj.org_ref !== '') {
                setCollabOption(collab_obj.org_ref)
            }
        }
    }, [])

    const optClass = (index) => {
        return index === selectedOpt ? 'selected' : '';
    }

    const onChoiceClick = (index) => {
        setSelectedOpt(index);
    }

    const guestLogin = (user_group) => {
        setLoading(true);
        loadNewGuestUser(
            setAuthToken,
            setAuthUser,
            setToastShow,
            setToastMessage,
            setProfile,
            history,
            user_group
        ).then(r => {
            setLoading(false);
        }).catch(e => {
            setLoading(false)
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__err_loading_guest'))
        })
    }

    const continueAction = async () => {

        if (collabOption && collabOption !== '') {
            guestLogin(collabOption);
        } else {
            guestLogin(options[selectedOpt]);
        }
    }

    const loginLink = () => {
        history.push('/login', true);
    }

    return (
        <div className={'dq-audience-container'}>
            <Helmet title="Digital Citizenship Test" />
            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="audience-section-dq-image"><img src={dqIcon} alt='DQ'/></div>

            <p className={'dq-audience-title'}>{ ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__welcome_dct_text')) }!</p>

            {/*<div className={'dq-audience-options'}>*/}
            {/*    {*/}
            {/*        options.map((opt, index) => */}
            {/*        <span className={optClass(index)} onClick={() => {onChoiceClick(index)}} dangerouslySetInnerHTML={{__html: opt}}></span>)*/}
            {/*    }*/}
            {/*</div>*/}

            <br/>
            <div className={'dq-options-submit'}
            >
                <button onClick={continueAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__start_test_text')}</button>
            </div>

            <br/>
            <div className={'dq-audience-login'}>
                <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__already_have_account')}</p>
                <a onClick={loginLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login_here')}</a>
            </div>

            <Footer />

        </div>
    );
};


export default withRouter(DqOnlyInfo);
