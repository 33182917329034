import React, {useEffect, useState} from 'react';
import {getActiveThemeColor} from "../../../utils/utils";

export const btnSize = {
    lg: 1,
    md: 2,
    sm: 3
}

const BorderButton = (props) => {

    const { size, action, title, themeColor } = props;

    const [btnClasses, setBtnClasses] = useState(`${'border-btn-ui'} ${'border-btn-md'}`);

    const getThemeColor = () => {
        return themeColor ? themeColor : '#f8b143'
    }

    useEffect( () => {

        if (size) {
            switch (size) {
                case 1:
                    setBtnClasses(`${'border-btn-ui'} ${'border-btn-lg'}`);
                    break;
                case 2:
                    setBtnClasses(`${'border-btn-ui'} ${'border-btn-md'}`);
                    break;
                case 3:
                    setBtnClasses(`${'border-btn-ui'} ${'border-btn-sm'}`);
                    break;
                default:
                    // do nothing
                    break;
            }
        }

    }, [] )

    return (
        <button
            className={btnClasses}
            style={{
                color: getThemeColor(),
                border: '2px dashed ' + getThemeColor()
            }}
            onClick={action}
        >
            {title}
        </button>
    );

};

export default BorderButton;