import React, {useEffect, useState} from 'react';
// import '../../../assets/css/ui.comp.css';

const ShareOnMedia = (props) => {

    const { title, themeColor } = props;

    const getThemeColor = () => {
        return themeColor ? themeColor : '#f8b143'
    }

    useEffect( () => {

    }, [] );

    const mainStyle = {
        backgroundColor: themeColor ? themeColor : '#f8b143'
    };

    return (
        <div className={'media-share-section'}>
            { 
                title ?
                    <div style={{color: getThemeColor()}}>{title}</div>
                :
                    null
            }
            <span style={mainStyle} className={'facebook'}></span>
            <span style={mainStyle} className={'twitter'}></span>
            <span style={mainStyle} className={'instagram'}></span>
            <span style={mainStyle} className={'tumblr'}></span>
        </div>
    );

};

export default ShareOnMedia;