import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import dqIcon from "../../../assets/icons/dq-icons/dq-logo.png";
import {useLocation} from "react-router-dom";
import {GET_PRIVACY_POLICY_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const PrivacyPolicy = (props) => {

    const {history} = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const goBack = () => {
        // history.goBack();
    }

    return (
        <div className="static-page-container">
            <img className='dq-logo' onClick={goBack} src={dqIcon} />
                <div className="static-page-title-text">
                    {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__title')}
                </div>

                <div className="static-page-section" id="section1">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__intro')}
                    </p>

                    <p className="static-page-section-content  ">
                        {ReactHtmlParser(GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_1'))}
                    </p>
                    <p className="static-page-section-content  ">
                        {ReactHtmlParser(GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_2'))}
                    </p>
                </div>

                <div className="static-page-section" id="section2">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__info_we_collect')}
                    </p>

                    <div className="static-page-section-content  ">
                        <p>
                            {ReactHtmlParser(GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_3'))}
                        </p>
                        <ul className=" ">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_personal_details')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_contact_details')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_other_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_device_properties')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_device_software')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_geographical_data')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_activity_progress')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_your_responses')}</li>
                        </ul>
                    </div>

                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p2_ending_note')}
                    </p>
                </div>

                <div className="static-page-section" id="section3">

                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__how_we_use_ur_info')}
                    </p>

                    <div className="  static-page-section-content">
                        <p>
                            {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_dq_may_use_ps_info')}
                        </p>
                        <ul className=" ">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_send_msg_about_dqlab')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_send_msg_about_research')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_send_msg_about__new_feat')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_send_msg_about_prom_material')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_send_message_about_marketing')}</li>
                        </ul>
                    </div>

                    <div className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_dq_may_use_combine_ps_info')}
                        <ul className=" ">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_for_publish_research_articles')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_for_feedback_purpose')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_for_marking_purpose')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_for_statistical_analysis')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_for_content_improve')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_for_tech_support')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_obtain_views_on_services')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_to_verify_id')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_to_provide_service')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_to_customize_content')}</li>
                        </ul>
                        <p></p>

                        <p className="  static-page-section-content">
                            {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_4')}
                        </p>

                        <ol className="  lower-alpha-braket">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_5')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p3_comply_with_legal_obligations')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_6')}</li>
                        </ol>

                        <p className="  static-page-section-content">
                            {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_7')}
                        </p>

                    </div>

                </div>

                <div className="static-page-section" id="section4">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__automated_processing')}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_8')}
                    </p>
                </div>

                <div className="static-page-section" id="section5">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__info_sharing')}
                    </p>


                    <div className="  static-page-section-content">
                        <p>
                            {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_9')}
                        </p>
                        <ul className=" ">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_10')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_11')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_12')}</li>
                        </ul>
                    </div>

                    <p className="  static-page-section-content">
                        <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_13')}</li>
                    </p>
                </div>

                <div className="static-page-section" id="section6">
                    <p className="static-page-section-title">
                        <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_6')}</li>
                    </p>
                    <div className="static-page-section-content">
                        <p className=" ">
                            {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_14')}
                        </p>
                        <ol type="i" className=" ">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_limiting_collection_of_ps_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_mandating_strog')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_utilize_secure_login')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_ensuring_dqlab_info_tech')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_implementing_robust_policy')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_anonymize_personal_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_req_for_updated_copy')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_req_updates_for_ps_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_parents_ability_to_req_access')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_15')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p6_dqlab_recommends_parents')}</li>
                        </ol>
                    </div>
                </div>

                <div className="static-page-section" id="section7">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_7')}
                    </p>
                    <p className="static-page-section-content  ">
                        {ReactHtmlParser(GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_16'))}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_17')}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p7_you_acknowledge_that')}
                    </p>
                </div>

                <div className="static-page-section" id="section8">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_8')}
                    </p>
                    <p className="static-page-section-content ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_18')}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p8_may_req_a_copy')}
                    </p>
                </div>

                <div className="static-page-section" id="section9">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_9')}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_19')}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_20')}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_21')}
                    </p>
                </div>

                <div className="static-page-section" id="section10">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_10')}
                    </p>
                    <p className="static-page-section-content  ">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_22')}
                    </p>

                    <div className="static-page-section-content">
                        <p className="static-page-section-content  ">
                            {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_23')}
                        </p>
                        <ul className=" ">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p10_maintain_records')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p10_comply_with_record')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p10_defend_or_bring')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p10_deal_with_complaints')}</li>
                        </ul>

                    </div>

                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_24')}
                    </p>
                </div>

                <div className="static-page-section" id="section11">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_11')}
                    </p>
                    <div className="static-page-section-content">
                        <p className="">
                            {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_25')}
                        </p>
                        <ol className=" ">
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_access_ps_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_rectify_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_erase_ps_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_restict_use_of_ps_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_objection_on_ps_info')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_26')}</li>
                            <li>{GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_lodge_complaint')}</li>
                        </ol>
                    </div>

                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_contact_to_discuss_rights')}
                    </p>
                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_27')}
                    </p>
                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p11_will_contact_for_additional_info')}
                    </p>
                </div>

                <div className="static-page-section" id="section12">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_12')}
                    </p>
                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_28')}
                    </p>
                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_29')}
                    </p>
                </div>

                <div className="static-page-section" id="section12">
                    <p className="static-page-section-title">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__point_13')}
                    </p>
                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__p13_dqlab_relevant_data')}
                    </p>
                    <p className="  static-page-section-content">
                        {ReactHtmlParser(GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_30'))}
                    </p>

                    <p className="  static-page-section-content">
                        {ReactHtmlParser(GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_31'))}
                    </p>
                    <p className="  static-page-section-content">
                        {GET_PRIVACY_POLICY_LOCALIZE_TEXT(pageLc, 'privacy_policy__para_32')}
                    </p>
                </div>

        </div>
    );
}

export default withRouter(PrivacyPolicy);
