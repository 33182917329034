import React, {useState, useEffect, useRef} from "react";
import {getActiveThemeColor} from "../../../utils/utils";
import {useGlobalState} from "../../../context/global_state";
import BalloonGamePoster from "./BalloonGamePoster.comp";
import TFQSliderPoster from "./TFQSliderPoster.comp";
import Footer from "../../../components/Footer/plugable.footer";
import ReactHtmlParser from "react-html-parser";
import {DQ_THEMES, NAV_DIRECTION} from "../../../utils/constants";
import LSQSliderPoster from "./LSQSliderPoster.com";
import styled from "styled-components";

function getIndexOf(searchStr, str, caseSensitive, startIndex = 0) {
    let searchStrLen = searchStr.length;
    if(searchStrLen == 0)
    {
        return -1;
    }
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    return str.indexOf(searchStr, startIndex);
}

function replaceKeyword(searchStr, replaceStr, parentStr, caseSensitive = false)
{
    let startIndex = 0;
    let index = -1;
    while((index = getIndexOf(searchStr, parentStr, caseSensitive, startIndex)) > -1)
    {
        const firstStr = parentStr.slice(0, index)
        const lastStr = parentStr.slice(index + searchStr.length, parentStr.length)
        parentStr = firstStr + replaceStr + lastStr
        startIndex = (firstStr + replaceStr).length;
    }
    return parentStr;
}

const QuestionPoster = (props) => {

    const { question, subtitle_top, subtitle_bottom, choices, multi_select, question_number, code, type } = props.question;
    const { answers, updateAnswers, questionNextAction, questionPrevAction
        , show_alias_keys, hide_alias_keys, nav_direction, step_skip_count_ref, enable_qa_mode} = props;
    const { is_ui_rules_exist, ui_rules } = props.question;
    const [ inputQuestions, setInputQuestions ] = useState([]);
    const [ unselectQuestions, setUnselectQuestions ] = useState([]);
    const [ questionHTML, setQuestionHTML ] = useState(question);
    const [ choicesHTML, setChoicesHTML ] = useState(choices);
    const [ toolTipText, setToolTipText ] = useState('');
    const [ displayTooltip, setDisplayTooltip ] = useState(false);
    const [tooltipPos, setToolTipPos ] = useState({x: 0, y: 0});
    //const [themeName, setThemeName ] = useState(props.theme.name);
    const [subtitleTopHTML, setSubtitleTopHTML] = useState(subtitle_top);

    const { profile, settings } = useGlobalState();

    const choicesDiv = useRef(null);

    let tips_length = subtitle_top.length + subtitle_bottom.length;


    const resetAnswers = () => {
        let _answers = answers;
        _answers[question_number] = {
            code: code,
            multi_select: multi_select,
            selIndexes: [],
            selectedIndex: -1
        }
        updateAnswers(_answers);
    }

    const linkifyText = (text) => {
        if (!text) return text;
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
    };


    useEffect( () => {
        if (!(question_number in answers)) {
            resetAnswers();
        }
        if (choicesDiv.current) {
            choicesDiv.current.scrollTop = 0;
        }

        const input_indices = []
        const unselect_indices = []
        let modified_question = question;
        let modified_choices = choices;
        let skip_this_step = false;
        ui_rules.forEach((rule) => {
            switch(rule.type) {
                case "INDEX_EFFECT":
                    if (rule.effect === "USER_INPUT") {
                        input_indices.push(rule.index)
                    }
                    else if(rule.effect === "UNSELECT") {
                        unselect_indices.push(rule.index)
                    }
                    break;
                case "QUESTION_EFFECT":
                    if (rule.effect === "TOOLTIP") {
                        modified_question = replaceKeyword(rule.text, `<tooltip text='${rule.text}' html='${rule.html}'></tooltip>`, modified_question)
                    }
                    break;
                case "ANSWER_EFFECT":
                    if (rule.effect === "TOOLTIP") {
                        modified_choices = modified_choices.map((choice) => replaceKeyword(rule.text, `<tooltip text='${rule.text}' html='${rule.html}'></tooltip>`, choice))
                    }
                    break;
                case "ALIAS_KEY_LOOKUP":
                    if (rule.effect === "SHOW" && show_alias_keys) {
                        if (!show_alias_keys.includes(rule.alias_key)) {
                            skip_this_step = true;
                        }
                    }
                    if (rule.effect === "HIDE" && hide_alias_keys) {
                        if (hide_alias_keys.includes(rule.alias_key)) {
                            skip_this_step = true;
                        }
                    }
                    break;
            }
        });
        if (skip_this_step) {
            if (nav_direction === NAV_DIRECTION.FORWARD) {
                step_skip_count_ref.current += 1;
                resetAnswers();
                questionNextAction(true);
            } else {
                step_skip_count_ref.current -= 1;
                questionPrevAction();
            }
        }
        let modified_subtitle_top = subtitle_top;
        modified_subtitle_top = linkifyText(modified_subtitle_top);
        setSubtitleTopHTML(modified_subtitle_top);
        setQuestionHTML(modified_question);
        setChoicesHTML(modified_choices);
        setInputQuestions(input_indices);
        setUnselectQuestions(unselect_indices);

        // auto-taking test,
        if (enable_qa_mode && choices && choices.length > 1) {
            onChoiceClick(getRandomInt(0, choices.length - 1));
        }
    }, [question_number])

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const onChoiceClick = (index) => {
        let _answers = answers;
        if (type === 'YNQ') {
            index = (index+1) % choices.length;
        }
        if (_answers[question_number]) {
            if (_answers[question_number].multi_select) {

                let data = _answers[question_number].selIndexes;

                if (data.includes(index)) {
                    const _index = data.indexOf(index);
                    if (_index > -1) {
                        data.splice(_index, 1);
                    }
                    _answers[question_number].selIndexes = [...data];
                    updateAnswers(_answers);
                } else {
                    if(is_ui_rules_exist)
                    {
                        data.forEach((val, index_to_remove) => {
                             if(unselectQuestions.includes(val)) {
                                 data.splice(index_to_remove, 1);
                             }
                        })
                        if(unselectQuestions.includes(index))
                        {
                            data = [];
                        }
                    }
                    else
                    {
                        data.map( choice => {
                            if (choices[choice].trim().toLowerCase().includes('none of the above') ||
                                choices[choice].trim().toLowerCase().includes('all of the above') ||
                                choices[choice].trim().toLowerCase().includes('해당하는 선택지 없음.')
                            ) {
                                const _index = data.indexOf(choice);
                                if (_index > -1) {
                                    data.splice(_index, 1);
                                }
                            }
                        })
                        if (choices[index].trim().toLowerCase().includes('none of the above') ||
                            choices[index].trim().toLowerCase().includes('all of the above') ||
                            choices[index].trim().toLowerCase().includes('해당하는 선택지 없음.')
                        ) {
                            data = [];
                        }
                    }
                    data.push(index);
                    _answers[question_number].selIndexes = [...data];
                    updateAnswers(_answers);
                }
            } else {
                let selectedIndex = _answers[question_number].selectedIndex;
                if (selectedIndex === index) {
                    _answers[question_number].selectedIndex = -1;
                    updateAnswers(_answers);
                } else {
                    _answers[question_number].selectedIndex = index;
                    updateAnswers(_answers);
                }
            }
        }
        // auto-next question after choice-click
        if (enable_qa_mode) questionNextAction();
    }

    const onChoiceInputChange = (index, event) => {
        let _answers = answers;
        _answers[question_number].custom_input = event.target.value;
        if(event.target.value === '')
        {
            if(_answers[question_number].multi_select)
            {
                const _index = _answers[question_number].selIndexes.indexOf(index);
                if(_index !== -1)
                {
                    _answers[question_number].selIndexes.splice(_index, 1)
                }
            }
            else
            {
                if(_answers[question_number].selectedIndex === index)
                {
                    _answers[question_number].selectedIndex = -1;
                }
            }
        }
        else
        {
            if(_answers[question_number].multi_select)
            {
                if(is_ui_rules_exist) {
                    _answers[question_number].selIndexes.forEach((val, index_to_remove) => {
                        if (unselectQuestions.includes(val)) {
                            _answers[question_number].selIndexes.splice(index_to_remove, 1);
                        }
                    })
                }
                const _index = _answers[question_number].selIndexes.indexOf(index);
                if(_index === -1)
                {
                    _answers[question_number].selIndexes.push(index)
                }
            }
            else
            {
                _answers[question_number].selectedIndex = index;
            }
        }
        updateAnswers(_answers);
    }

    const checkIfSelected = (index) => {
        let _answers = answers;
        if (type === 'YNQ') {
            index = (index+1) % choices.length;
        }
        if (_answers[question_number]) {
            if (_answers[question_number].multi_select) {
                return _answers[question_number].selIndexes.includes(index);
            } else {
                return index === _answers[question_number].selectedIndex;
            }
        }
    }

    const answerColor = (index) => {
        return checkIfSelected(index) ? '#fff' : '#000';
    }

    const answerBgColor = (index) => {
        return checkIfSelected(index) ? getActiveThemeColor(profile) : '';
    }

    const onHoverTooltip = (event, text) => {
        setToolTipPos({x: event.pageX, y: event.pageY})
        setToolTipText(text);
        setDisplayTooltip(true);
    }

    const onExitHoverTooltip = () => {
        setToolTipPos({x: 0, y: 0})
        setDisplayTooltip(false);
    }

    const QuizTimeContainer = styled.div`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[0]}", "${props.theme.fonts[1]}"`;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[4]}"`;
                default:
                    return '';
            }
        }};
    `
    const QuizAnswer = styled.span`
        font-family: ${(props) => {
            switch (props.theme.name) {
                case DQ_THEMES.DEFAULT:
                    return `"${props.theme.fonts[0]}", "${props.theme.fonts[1]}"`;
                case DQ_THEMES.NUS_THEME:
                    return `"${props.theme.fonts[4]}"`;
                default:
                    return '';
            }
        }};
    `


    return (
        <div>
            <div className="qt-tooltip-content" style={{opacity: displayTooltip ? 100 : 0, backgroundColor: getActiveThemeColor(profile), top: tooltipPos.y, left: tooltipPos.x}}>
                {ReactHtmlParser(toolTipText)}
            </div>
            <div className={'qt-question-container'}>
                <QuizTimeContainer className={`qt-question-content qt-font-${parseInt((tips_length+question.length)/50)}`}>
                    {
                        subtitle_top.length > 0 && <div
                            style={{color: getActiveThemeColor(profile)}}
                            className={'qt-question-tips'} >
                            <span>{ReactHtmlParser(subtitleTopHTML)}</span>
                        </div>
                    }
                    <div className={'qt-question-body'} >
                        <span>{ReactHtmlParser(questionHTML, {transform: (node) => {
                            if (node.type === 'tag' && node.name === 'tooltip') {
                                return <span className="qt-tooltip" onMouseEnter={(event) => onHoverTooltip(event, node.attribs.html)} onMouseLeave={onExitHoverTooltip} style={{color: getActiveThemeColor(profile)}}>{node.attribs.text}
                                </span>
                            }
                        }})}</span>
                    </div>
                    {
                        subtitle_bottom.length > 0 && <div
                            style={{color: getActiveThemeColor(profile)}}
                            className={`qt-question-tips stb-font-${parseInt((subtitle_bottom.length)/50)}`} >
                            <span>{ReactHtmlParser(subtitle_bottom)}</span>
                        </div>
                    }
                </QuizTimeContainer>
            </div>

            {
                type === 'GTQ' ?
                    <BalloonGamePoster
                        choices={choices}
                        onChoiceClick={onChoiceClick}
                        questionNextAction={questionNextAction}
                    />
                    :
                    <div
                        ref={choicesDiv}
                     className={`qt-answer-container ${type === 'LSQ' && choices.length === 7 ? ' qt-lsq-7' : ''} qt-${type.toLowerCase()} qt-opt-${choices.length} qt-theme-${settings.theme.toLowerCase()}`}>
                        {
                           type === 'LSQ' && choices.length === -1 ?
                               <>
                                   <LSQSliderPoster
                                       choices={choices}
                                       onChoiceClick={onChoiceClick}
                                   />
                                   <Footer />
                               </>
                               :
                            type === 'TFQ' && choices.length === -1 ?
                                <>
                                    <TFQSliderPoster
                                        choices={choices}
                                        onChoiceClick={onChoiceClick}
                                    />
                                </>
                            :
                                <>
                                {
                                    choices.map( (choice, index) => inputQuestions.includes(index) ? <input
                                        type="text"
                                        name="choice"
                                        placeholder={choice}
                                        value={answers[question_number] ? answers[question_number].custom_input : ''}
                                        style={{
                                            border: '2px solid ' + getActiveThemeColor(profile),
                                            color: answerColor(index),
                                            backgroundColor: answerBgColor(index),

                                        }}
                                        className={'qt-answer-span'}
                                        onChange={(event) => {onChoiceInputChange(index, event)}}
                                    /> :
                                        <QuizAnswer
                                        style={{
                                            border: '2px solid ' + getActiveThemeColor(profile),
                                            color: answerColor(index),
                                            backgroundColor: answerBgColor(index)
                                        }}
                                        className={'qt-answer-span'}
                                        onClick={() => {onChoiceClick(index)}}
                                    >
                                        {ReactHtmlParser(choicesHTML[index], {transform: (node) => {
                                                if (node.type === 'tag' && node.name === 'tooltip') {
                                                    return <span
                                                        className="qt-tooltip"
                                                        onMouseEnter={(event) => onHoverTooltip(event, node.attribs.html)}
                                                        onMouseLeave={onExitHoverTooltip}
                                                        style={{textDecoration: "underline"}}>{node.attribs.text}
                                                    </span>
                                                }
                                            }})}
                                    </QuizAnswer>
                                            )
                                }
                                {/* <div className="qt-white-mask-top"></div>
                                <div className="qt-white-mask-bot"></div> */}
                                </>
                        }
                </div>
            }

        </div>

    )
};

export default QuestionPoster;
