import React, {useState, useEffect} from "react";
import { sha256 } from 'js-sha256';
import dqIcon from "../../assets/icons/dq-icons/dq-logo.png";
import {LOCAL_STORAGE_KEYS} from "../../utils/constants";
import {useGlobalState} from "../../context/global_state";

import { useHistory } from 'react-router-dom';

const LockScreenPage = () => {

    const history = useHistory();

    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');

    const { setToastShow, setToastMessage} = useGlobalState();

    useEffect( () => {
        const access_key = localStorage.getItem(LOCAL_STORAGE_KEYS.UNDER_MAINTENANCE_ACCESS_KEY);
        const pass_key = process.env.REACT_APP_UNDER_MAINTENANCE_PASS_KEY
        const pass_hash = sha256(pass_key);

        if (access_key === pass_hash) {
            history.push('/');
        }
    }, [] )

    const onChangeEmail = (e) => {
        setFormEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setFormPassword(e.target.value);
    }

    const unLockAction = () => {
        // const access_key = localStorage.getItem(LOCAL_STORAGE_KEYS.UNDER_MAINTENANCE_ACCESS_KEY);

        if (formEmail !== 'admin') {
            setToastShow(true);
            setToastMessage('wrong username entered!');
            return
        }

        const pass_key = process.env.REACT_APP_UNDER_MAINTENANCE_PASS_KEY
        if (formPassword !== pass_key) {
            setToastShow(true);
            setToastMessage('wrong password entered!');
            return
        }

        const pass_hash = sha256(pass_key);
        console.log('pass_hash --> ', pass_hash);
        localStorage.setItem(LOCAL_STORAGE_KEYS.UNDER_MAINTENANCE_ACCESS_KEY, pass_hash);

        history.push('/')
    }

    return (
        <div className={'login-container'}>

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>
            <h2
                style={{
                    margin: '12px'
                }}
            >The DQ Assessment is currently under maintenance.</h2>
            <div className="dq-login-content">
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='text'
                            name={'email'}
                            id={'email'}
                            value={formEmail}
                            placeholder={'Username'}
                            onChange={onChangeEmail}
                        />
                    </div>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='password'
                            name={'password'}
                            id={'password'}
                            value={formPassword}
                            placeholder={'Password'}
                            onChange={onChangePassword}
                        />
                    </div>
                </form>
            </div>

            {/*<div className={'dq-options-submit'}>*/}
            {/*    <button onClick={unLockAction}>Unlock</button>*/}
            {/*</div>*/}

            <div className={'dq-audience-login'}>
                <a onClick={unLockAction}>Admin Login</a>
            </div>

        </div>
    )
}

export default LockScreenPage;