import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const ListItemProgress = (props) => {

    const { badge, profile } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [scoreBoxText, setScoreBoxText] = useState('Start');
    const [progressClasses, setProgressClasses] = useState('pie-wrapper progress-0');
    const [isFinished, setIsFinished] = useState(0);

    useEffect( ()=>{
        const curr_badge_state = profile.badges[badge.badge_code];
        if (curr_badge_state) {
            if (curr_badge_state.in_progress) {
                setScoreBoxText(`${curr_badge_state.progress.toFixed(0)}%`)
                setProgressClasses(`pie-wrapper progress-${curr_badge_state.progress.toFixed(0)-curr_badge_state.progress.toFixed(0)%5}`)
            } else if (curr_badge_state.finished) {
                setIsFinished(1);
                setScoreBoxText(`${parseFloat(curr_badge_state.obtained_score).toFixed(2)}`)
            }
        }
    }, [profile] );

    return(
        <div className={'home-list-item-progress-section'}>
            {
                isFinished === 1 ?
                    <div className={'score-label'}>
                        <span>{scoreBoxText.split(".")[0]}</span>
                        <span className={'score-label-pt'}>.{scoreBoxText.split(".")[1]}</span>
                    </div>
                : 
                    <div className={progressClasses}>
                        {
                            scoreBoxText === 'Start' ?
                                <span className={"label smaller"}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__start_test_text')}</span>
                            :
                                <span className={"label"}>{scoreBoxText}</span>
                        }

                        <div className={"pie"}>
                            <div className={"left-side half-circle"}></div>
                            <div className={"right-side half-circle"}></div>
                        </div>
                        <div className={"pie-border"}></div>
                    </div> 
            }
        </div>
    )
}

export default ListItemProgress;