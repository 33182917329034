import React, {useEffect, useState} from 'react';
// import Button, {btnSize} from "../../components/UI/Button/Button.ui";
import BorderButton , {btnSize} from "../../components/UI/BorderButton/BorderButton.ui";

import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import {finishAssessService} from "../../services/home.service";
import {useGlobalState} from "../../context/global_state";

import rubricImg from '../../assets/images/score-rubric.png';
import rubricImgDesktop from '../../assets/images/rubric-desktop.jpg';
import rubricImgMobile from '../../assets/images/rubric-mobile.jpg';
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {LOCAL_STORAGE_KEYS} from "../../utils/constants";
import {getActiveThemeColor, getMapFromObjectListByKey} from "../../utils/utils";
import {logoutUser} from "../../services/auth.service";
import {useAuth} from "../../context/auth";

const QuizInfo = (props) => {

    const { history } = props;

    const { setToastShow, setToastMessage, setProfile, profile } = useGlobalState();


    useEffect( () => {

        // will be implemented later, for now redirect straight away to /info_dq
        history.push('/info_dq');

    }, []);



    return (
        <div className={'quiz-result-container'}>

            <p>please wait...</p>

        </div>
    )
};

export default withRouter(QuizInfo);
